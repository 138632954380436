import { Box, CircularProgress, CircularProgressProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { VFC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    align: "center",
    verticalAlign: "middle",
  },
  spinner: {},
  circle: {
    strokeLinecap: "round",
  },
}));

export type LoadingIconProps = CircularProgressProps & {};

export const LoadingIcon: VFC<LoadingIconProps> = ({ className, size = 18, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress size={size} className={classes.spinner} classes={{ circle: classes.circle }} {...rest} />
    </Box>
  );
};
